.aside {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: var(--background-color-aside);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: var(--padding-aside-v) var(--padding-aside-h);
  box-sizing: border-box;
  gap: var(--gap-base);
  overflow-y: auto;
}

.aside.collapsed {
  width: var(--sidebar-width-collapsed);
}

.aside.expanded {
  width: var(--sidebar-width);
}

.buttonGroup {
  display: flex;
  flex-direction: column;
  gap: var(--gap-base);
}
