:root {
  --text-xxxl-size: 2.5rem; /* 40px */
  --text-xxxl-height: 3.5rem; /* 56px */
  --text-xxl-size: 2.125rem; /* 34px */
  --text-xxl-height: 3rem; /* 48px */
  --text-xl-size: 1.625rem; /* 26px */
  --text-xl-height: 2.25rem; /* 36px */
  --text-lg-size: 1.375rem; /* 22px */
  --text-lg-height: 2rem; /* 32px */
  --text-base-size: 1.125rem; /* 18px */
  --text-base-height: 1.5rem; /* 24px */
  --text-sm-size: 0.9375rem; /* 15px */
  --text-sm-height: 1.25rem; /* 20px */
  --text-xs-size: 0.75rem; /* 112px */
  --text-xs-height: 1rem; /* 16px */
  --font-weight-base: 400;
  --size-quarter: 0.0625rem; /* 1px */
  --size-half: 0.125rem; /* 2px */
  --size-1: 0.25rem; /* 4px */
  --size-2: 0.5rem; /* 8px */
  --size-3: 0.75rem; /* 12px */
  --size-4: 1rem; /* 16px */
  --size-5: 1.25rem; /* 20px */
  --size-6: 1.5rem; /* 24px */
  --size-7: 1.75rem; /* 28px */
  --size-8: 2rem; /* 32px */
  --size-9: 2.25rem; /* 36px */
  --size-10: 2.5rem; /* 40px */
  --size-11: 2.75rem; /* 44px */
  --size-12: 3rem; /* 48px */
  --size-14: 3.5rem; /* 56px */
  --size-16: 4rem; /* 64px */
  --size-20: 5rem; /* 80px */
  --size-24: 6rem; /* 96px */
  --size-28: 7rem; /* 112px */
  --size-30: 7.5rem; /* 120px */
  --size-32: 8rem; /* 128px */
  --size-36: 9rem; /* 144px */
  --size-40: 10rem; /* 160px */
  --size-44: 11rem; /* 176px */
  --size-48: 12rem; /* 192px */
  --size-52: 13rem; /* 208px */
  --size-56: 14rem; /* 224px */
  --size-60: 15rem; /* 240px */
  --size-64: 16rem; /* 256px */
  --size-68: 12rem; /* 272px */
  --size-72: 18rem; /* 288px */
  --size-80: 20rem; /* 320px */
  --size-96: 24rem; /* 384px */
  --size-128: 32rem; /* 512px */
  --size-144: 36rem; /* 576px */
  --size-160: 40rem; /* 640px */
  --size-192: 48rem; /* 768px */
  --size-224: 56rem; /* 896px */
  --size-240: 60rem; /* 960px */
  --size-256: 64rem; /* 1024px */
  --size-320: 80rem; /* 1280px */
  --spacing-1: var(--size-1);
  --spacing-2: var(--size-2);
  --spacing-3: var(--size-3);
  --spacing-4: var(--size-4);
  --spacing-6: var(--size-6);
  --spacing-8: var(--size-8);
  --spacing-10: var(--size-10);
  --gap-xs: var(--spacing-1);
  --gap-sm: var(--spacing-2);
  --gap: var(--spacing-3);
  --clr-neutral-0: #ffffff;
  --clr-neutral-50: #f6f6f6;
  --clr-neutral-100: #ededed;
  --clr-neutral-200: #c2c2c2;
  --clr-neutral-300: #969393;
  --clr-neutral-500: #7a7a7a;
  --clr-neutral-700: #383838;
  --clr-neutral-900: #1f1f1f;
  --clr-green-100: #ebf8f2;
  --clr-green-200: #c4ead7;
  --clr-green-500: #3aba7b;
  --clr-green-700: #25955e;
  --clr-green-900: #0f7241;
  --clr-blue-100: #ecf1f8;
  --clr-blue-200: #c5d6eb;
  --clr-blue-500: #3e76bd;
  --clr-blue-700: #255a9c;
  --clr-blue-900: #062d5e;
  --clr-red-100: #f8ebea;
  --clr-red-200: #ebc3bf;
  --clr-red-500: #bc362b;
  --clr-red-700: #a7261c;
  --clr-red-900: #78120a;
  --clr-yellow-100: #fffce6;
  --clr-yellow-200: #fef5b3;
  --clr-yellow-500: #fdde03;
  --clr-yellow-700: #e3c805;
  --clr-yellow-900: #b7a003;
  --clr-purple-100: #f4eef6;
  --clr-purple-200: #decde3;
  --clr-purple-500: #9057a3;
  --clr-purple-700: #7b3f8e;
  --clr-purple-900: #4b185b;
  --clr-orange-100: #fcf5e7;
  --clr-orange-200: #f7e1b8;
  --clr-orange-500: #e39b13;
  --clr-orange-700: #d08a06;
  --clr-orange-900: #a16b05;
  --color-base: var(--clr-neutral-700);
  --color-base-hover: var(--clr-neutral-900);
  --color-lighter: var(--clr-neutral-500);
  --color-lighter-hover: var(--clr-neutral-700);
  --color-red-base: var(--clr-red-700);
  --color-red-base-hover: var(--clr-red-900);
  --color-red-lighter: var(--clr-red-500);
  --color-red-lighter-hover: var(--clr-red-700);
  --color-blue-base: var(--clr-blue-700);
  --color-blue-base-hover: var(--clr-blue-900);
  --color-blue-lighter: var(--clr-blue-500);
  --color-blue-lighter-hover: var(--clr-blue-700);
  --color-green-base: var(--clr-green-700);
  --color-green-base-hover: var(--clr-green-900);
  --color-green-lighter: var(--clr-green-500);
  --color-green-lighter-hover: var(--clr-green-700);
  --color-purple-base: var(--clr-purple-700);
  --color-purple-base-hover: var(--clr-purple-900);
  --color-purple-lighter: var(--clr-purple-500);
  --color-purple-lighter-hover: var(--clr-purple-700);
  --color-orange-base: var(--clr-orange-700);
  --color-orange-base-hover: var(--clr-orange-900);
  --color-orange-lighter: var(--clr-orange-500);
  --color-orange-lighter-hover: var(--clr-orange-700);
  --color-yellow-base: var(--clr-yellow-700);
  --color-yellow-base-hover: var(--clr-yellow-900);
  --color-yellow-lighter: var(--clr-yellow-500);
  --color-yellow-lighter-hover: var(--clr-yellow-700);
  --rounded-sm: 0.125rem; /* 2px */
  --rounded: 0.25rem; /* 4px */
  --rounded-md: 0.375rem; /* 6px */
  --rounded-full: 9999px;
  /* ----------------------------------------------------------------- */
  /* font-size */
  --font-size-header-1: var(--text-xxxl-size);
  --font-size-header-2: var(--text-xxl-size);
  --font-size-header-3: var(--text-xl-size);
  --font-size-header-4: var(--text-lg-size);
  --font-size-paragraph: var(--text-base-size);
  --font-size-paragraph-2: var(--text-sm-size);
  --font-size-paragraph-3: var(--text-xs-size);
  --font-size-input: var(--text-base-size);
  --font-size-button: var(--text-sm-size);
  --font-size-label: var(--text-sm-size);
  --font-size-tab: var(--text-base-size);
  --font-size-tag: var(--text-base-size);
  --font-size-meta: var(--text-xs-size);
  --font-size-table-header: var(--text-sm-size);
  --font-size-table-row: var(--text-base-size);
  /* line-height */
  --line-height-header-1: var(--text-xxxl-height);
  --line-height-header-2: var(--text-xxl-height);
  --line-height-header-3: var(--text-xl-height);
  --line-height-header-4: var(--text-lg-height);
  --line-height-paragraph: var(--text-base-height);
  --line-height-paragraph-2: var(--text-sm-height);
  --line-height-paragraph-3: var(--text-xs-height);
  --line-height-input: var(--text-base-height);
  --line-height-button: var(--text-sm-height);
  --line-height-label: var(--text-sm-height);
  --line-height-tab: var(--text-base-height);
  --line-height-tag: var(--text-base-height);
  --line-height-meta: var(--text-xs-height);
  --line-height-table-header: var(--text-sm-height);
  --line-height-table-row: var(--text-base-height);
  /* color */
  --color-label: var(--color-lighter);
  --color-table-header: var(--color-label);
  --color-table-header-hover: var(--color-base);
  --color-button: var(--color-base);
  --color-button-hover: var(--color-base-hover);
  --color-button-blue: var(--color-blue-base);
  --color-button-blue-hover: var(--color-blue-base-hover);
  --color-button-red: var(--color-red-base);
  --color-button-red-hover: var(--color-red-base-hover);
  --color-button-green: var(--color-green-base);
  --color-button-green-hover: var(--color-green-base-hover);
  --color-button-important: var(--clr-neutral-0);
  --color-button-important-hover: var(--clr-neutral-0);
  --color-tag: var(--color-lighter);
  --color-tag-blue: var(--color-blue-lighter);
  --color-tag-red: var(--color-red-lighter);
  --color-tag-green: var(--color-green-lighter);
  --color-tag-purple: var(--color-purple-lighter);
  --color-link: var(--color-blue-lighter);
  --color-link-hover: var(--color-blue-lighter-hover);
  --color-meta: var(--color-lighter);
  --background-color-base: var(--clr-neutral-0);
  --background-color-secondary: var(--clr-neutral-50);
  --background-color-aside: var(--background-color-secondary);
  --background-color-sidenav-selected: var(--clr-neutral-200);
  --background-color-sidenav-selected-hover: var(--clr-neutral-300);
  --background-color-table-header: var(--background-color-base);
  --background-color-table-row: var(--background-color-base);
  --background-color-button-base: var(--clr-neutral-100);
  --background-color-button-hover: var(--clr-neutral-200);
  --background-color-button-important: var(--clr-blue-500);
  --background-color-button-important-hover: var(--clr-blue-700);
  --background-color-button-pushed: var(--clr-neutral-500);
  --background-color-button-pushed-hover: var(--clr-neutral-700);
  --background-color-tag: var(--clr-neutral-100);
  --background-color-tag-blue: var(--clr-blue-100);
  --background-color-tag-red: var(--clr-red-100);
  --background-color-tag-green: var(--clr-green-100);
  --background-color-tag-purple: var(--clr-purple-100);
  --background-color-tag-orange: var(--clr-orange-100);
  --background-color-tag-yellow: var(--clr-yellow-100);
  --border-color-input: var(--clr-neutral-200);
  --border-color-table-header: var(--clr-neutral-200);
  --border-color-table: var(--background-color-base);
  --border-color-tab: var(--clr-neutral-200);
  --border-color-separator: var(--clr-neutral-200);
  /* gaps */
  --gap-list: var(--size-1);
  --gap-base: var(--size-2);
  --gap-modal-footer: var(--gap-base);
  --gap-form-item: var(--gap-base);
  /* dimmensions */
  --sidebar-width: var(--size-64);
  --sidebar-width-collapsed: var(--size-16);
  --app-width: calc(100vw - var(--sidebar-width-collapsed) - 20px);
  --app-width-with-sidebar: calc(100vw - var(--sidebar-width) - 20px);
  --height-button: var(--size-10);
  --row-height: var(--size-8);
  --height-textarea-base: calc(var(--row-height) * 2);
  --height-textarea-double: calc(var(--row-height) * 3);
  --height-textarea-triple: calc(var(--row-height) * 6);
  --width-line-quarter: var(--size-32);
  --width-line-half: calc(var(--width-line-quarter) * 2 + var(--gap-form-item));
  --width-line-three-quarters: calc(
    var(--width-line-quarter) * 3 + var(--gap-form-item) * 2
  );
  --width-line-base: calc(var(--width-line-half) * 2 + var(--gap-form-item));
  --width-line-one-and-a-half: calc(
    var(--width-line-half) * 3 + var(--gap-form-item) * 2
  );
  --width-line-double: calc(var(--width-line-base) * 2 + var(--gap-form-item));
  --width-label-short: calc(
    var(--width-line-quarter) / 2 - var(--gap-form-item) / 2
  );
  --width-label-base: var(--width-line-quarter);
  --width-label-long: calc(var(--width-line-half) - var(--width-label-short));
  --width-form-modal: var(--width-line-base);
  --size-checkbox: var(--text-base-height);
  --height-dropdown: var(--size-48);
  --size-button-icon: var(--size-4);
  /* position */
  --modal-transform: translate(-50%, -50%);
  --modal-top: 50%;
  --modal-bottom: unset;
  --modal-left: 50%;
  --modal-right: unset;
  /* padding */
  --padding-app-v: var(--size-8);
  --padding-app-h: var(--size-12);
  --padding-aside-v: var(--size-4);
  --padding-aside-h: var(--size-3);
  --padding-page-header: var(--size-12);
  --padding-article-header: var(--size-6);
  --padding-section-header: var(--size-4);
  --padding-section-bottom: var(--size-6);
  --padding-section-item: calc(
    (var(--row-height) - var(--line-height-paragraph)) / 2
  );
  --padding-dialog-header: var(--size-4);
  --padding-dialog-footer: var(--size-4);
  --padding-button-h: var(--size-4);
  --padding-table-r: var(--size-4);
  --modal-padding: var(--size-8);
  --modal-padding-t: var(--size-4);
  --padding-label-v: calc(
    (var(--line-height-paragraph) - var(--line-height-label)) / 2
  );
  --padding-input-label-v: calc(
    (var(--row-height) - var(--line-height-label)) / 2
  );
  /* margins */
  --margin-article-bottom: var(--size-6);
  --margin-checkbox-v: calc((var(--row-height) - var(--size-checkbox)) / 2);
  /* corners */
  --border-radius-button: var(--rounded);
  /* overflow */
  --modal-overflow: unset;

  /*
  --header-group-direction: row;
  --header-group-align: center;
  --modal-padding: var(--spacing-6);
  --modal-top: 50%;
  --modal-bottom: unset;
  --modal-left: 50%;
  --modal-right: unset;
  --modal-transform: translate(-50%, -50%);
  --slide-show-aside-left: unset;
  --slideshow-aside-width: var(--size-128);
  --filelist-width: calc(var(--spacing-4) * 3 + 800px);
  --horizontal-padding: var(--spacing-4);
  --card-width: var(--size-52);
  --filelist-width: calc(var(--spacing-4) * 3 + 4 * var(--card-width));
  --app-width-max: calc(var(--filelist-width) + var(--size-20));
  --app-width-max-fullscreen: calc(
    var(--app-width-max) + var(--spacing-4) + var(--card-width)
  );
*/
}

* {
  font-family: "Open Sans", sans-serif;
  font-weight: var(--font-weight-base);
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-base);
  background-color: var(--background-color-base);
  font-size: var(--font-size-paragraph);
  line-height: var(--line-height-paragraph);
}

h1 {
  font-size: var(--font-size-header-1);
  line-height: var(--line-height-header-1);
}

h2 {
  font-size: var(--font-size-header-2);
  line-height: var(--line-height-header-2);
}

h3 {
  font-size: var(--font-size-header-3);
  line-height: var(--line-height-header-3);
}

h4 {
  font-size: var(--font-size-header-4);
  line-height: var(--line-height-header-4);
}

p {
  font-size: var(--font-size-paragraph);
  line-height: var(--line-height-paragraph);
  white-space: pre-wrap;
}

label {
  font-size: var(--font-size-label);
  line-height: var(--line-height-label);
  color: var(--color-label);
}

@media screen and (max-width: 768px) {
  :root {
    --modal-top: 0;
    --modal-bottom: 0;
    --modal-left: 0;
    --modal-right: 0;
    --modal-transform: unset;
    --modal-overflow: auto;
    --width-form-modal: 100%;
  }
}

@media screen and (min-width: 1024px) {
  :root {
    --width-form-modal: var(--width-line-one-and-a-half);
  }
}

@media screen and (min-width: 1400px) {
  :root {
    --width-form-modal: var(--width-line-double);
  }
}

@media screen and (max-height: 767px) {
  :root {
    --modal-top: 0;
    --modal-bottom: 0;
    --modal-left: 0;
    --modal-right: 0;
    --modal-transform: unset;
    --modal-overflow: auto;
    --width-form-modal: 100%;
  }
}

@media screen and (min-height: 1000px) {
  :root {
    --height-textarea-base: calc(var(--row-height) * 3);
    --height-textarea-double: calc(var(--row-height) * 6);
    --height-textarea-triple: calc(var(--row-height) * 9);
  }
}

@media screen and (min-height: 1200px) {
  :root {
    --height-textarea-base: calc(var(--row-height) * 4);
    --height-textarea-double: calc(var(--row-height) * 8);
    --height-textarea-triple: calc(var(--row-height) * 12);
  }
}
