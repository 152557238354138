.input {
  padding: var(--spacing-1) var(--spacing-3);
  font-size: var(--text-base-size);
  line-height: var(--text-base-height);
  border: 1px solid var(--clr-neutral-300);
  border-radius: var(--rounded);
  flex-grow: 1;
  width: 100%;
  box-sizing: border-box;
}

.constantText {
  margin: var(--padding-label-v) 0;
  padding: var(--padding-input-label-v) 0;
}

.checkbox {
  flex-grow: 0;
  accent-color: var(--background-color-button-important);
  width: var(--text-base-height);
  height: var(--text-base-height);
  margin: var(--margin-checkbox-v) 0;
  cursor: pointer;
}

.input:focus {
  outline-color: var(--background-color-button-important);
}

.inputWithLabel {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  gap: var(--gap-form-item);
}

.checkboxWithLabel {
  flex-direction: row-reverse;
  justify-content: flex-end;
  gap: var(--gap-form-item);
}

.invalid {
  border-color: var(--clr-red-700);
}

.invalid:focus {
  outline-color: var(--clr-red-700);
}

.quarter {
  width: var(--width-line-quarter);
  flex-grow: 0;
}

.half {
  width: var(--width-line-half);
  flex-grow: 0;
}
