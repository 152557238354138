.fieldset {
  display: flex;
  flex-direction: column;
  border: 0;
  gap: var(--gap-form-item);
  position: relative;
}

.legend {
  font-size: var(--font-size-header-4);
  line-height: var(--line-height-header-4);
  height: var(--line-height-header-4);
  margin-bottom: var(--padding-section-header);
}
