.textarea {
  padding: var(--spacing-1) var(--spacing-3);
  font-size: var(--text-base-size);
  line-height: var(--text-base-height);
  border: 1px solid var(--clr-neutral-300);
  border-radius: var(--rounded);
  flex-grow: 1;
  width: 100%;
  box-sizing: border-box;
  height: var(--height-textarea-base);
}

.double {
  height: var(--height-textarea-double);
}

.triple {
  height: var(--height-textarea-triple);
}

.textarea:focus {
  outline-color: var(--clr-sky-700);
}

.textareaWithLabel {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  gap: var(--gap-form-item);
}
