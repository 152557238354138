.button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--background-color-button-base);
  color: var(--color-button);
  font-size: var(--font-size-button);
  line-height: var(--line-height-button);
  cursor: pointer;
  border-color: transparent;
  height: var(--height-button);
  box-sizing: border-box;
  padding: 0 var(--padding-button-h);
  border-radius: var(--border-radius-button);
  text-decoration: none;
  gap: var(--gap-base);
}

.iconOnly {
  padding: unset;
  width: var(--height-button);
}

.button:hover {
  background-color: var(--background-color-button-hover);
  color: var(--color-button-hover);
}

.important {
  color: var(--color-button-important);
  background-color: var(--background-color-button-important);
}
.important:hover {
  color: var(--color-button-important-hover);
  background-color: var(--background-color-button-important-hover);
}

.inline {
  color: var(--color-button-blue);
  height: unset;
  background-color: transparent;
  justify-content: unset;
}
.inline:hover {
  color: var(--color-blue-base-hover);
  background-color: transparent;
  text-decoration: underline;
}
.inline.deemphasized {
  color: var(--color-table-header);
}
.inline.deemphasized:hover {
  color: var(--color-table-header-hover);
}

.navigation {
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
}

.unpadded {
  padding: 0;
}

.disabled {
  pointer-events: none;
  opacity: 0.2;
}
.inline.disabled {
  color: var(--color-lighter);
}

.sideNav {
  background-color: var(--background-color-aside);
  padding: 0 calc((var(--height-button) - var(--size-button-icon)) / 2);
  justify-content: flex-start;
}

.sideNav:hover {
  background-color: var(--background-color-button-base);
}

.pressed {
  font-weight: bold;
  background-color: var(--background-color-sidenav-selected);
}

.pressed:hover {
  background-color: var(--background-color-sidenav-selected-hover);
}
