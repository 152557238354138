.selectorContainer {
  display: flex;
  width: 100%;
  align-items: center;
  gap: var(--gap-form-item);
}

.quarter {
  width: var(--width-line-quarter);
  flex-grow: 0;
}

.half {
  width: var(--width-line-half);
  flex-grow: 0;
}

.dropdownSelector {
  display: flex;
  padding: var(--spacing-2) var(--spacing-4);
  font-size: var(--text-base-size);
  line-height: var(--text-base-height);
  border: 1px solid var(--clr-neutral-300);
  border-radius: var(--rounded-full);
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  height: calc(var(--text-base-height) + 2 * var(--spacing-2));
}

.chipSelector {
  flex-wrap: wrap;
}

.selectorOption {
  padding: var(--spacing-1) var(--spacing-3);
  cursor: pointer;
}

.selectorOption.selected {
  background-color: var(--clr-neutral-200);
}

.dropdownFilter {
  padding: var(--spacing-3);
  padding-bottom: 0;
  border-bottom: 1px solid var(--border-color-separator);
}

.dropdownContainer {
  overflow: auto;
  height: calc(
    var(--height-dropdown) - var(--row-height) - var(--spacing-3) -
      var(--padding-section-bottom) + 4px
  );
}
