.loaderRing {
  --loader-size: var(--size-20);
  --loader-weight: var(--size-2);
  --loader-margin: var(--size-2);
  --loader-opacity: 1;
  --loader-color: var(--clr-neutral-700);
}

.loaderRing.xs {
  --loader-size: var(--size-4);
  --loader-weight: var(--size-half);
  --loader-margin: var(--size-quarter);
}

.loaderRing.small {
  --loader-size: var(--size-10);
  --loader-weight: var(--size-1);
  --loader-margin: var(--size-1);
}

.loaderRing.important,
.loaderRing.alert,
.loaderRing.emphasized,
.loaderRing.warning {
  --loader-opacity: 1;
}

.loaderRing.important,
.loaderRing.alert {
  --loader-color: white;
}

.loaderRing.emphasized {
  --loader-color: var(--clr-blue-700);
}

.loaderRing.warning {
  --loader-color: var(--clr-red-700);
}

.loaderRing {
  --ring-diameter: calc(var(--loader-size) - var(--loader-margin) * 2);
}

.loaderRing {
  display: inline-block;
  position: relative;
  width: var(--loader-size);
  height: var(--loader-size);
  opacity: var(--loader-opacity);
  flex-shrink: 0;
}
.loaderRing div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: var(--ring-diameter);
  height: var(--ring-diameter);
  margin: var(--loader-margin);
  border: var(--loader-weight) solid;
  border-radius: 50%;
  animation: loaderRing 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--loader-color) transparent transparent transparent;
}
.loaderRing div:nth-child(1) {
  animation-delay: -0.45s;
}
.loaderRing div:nth-child(2) {
  animation-delay: -0.3s;
}
.loaderRing div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes loaderRing {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
