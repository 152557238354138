.popup {
  position: relative;
}

.dropdown {
  display: flex;
  flex-grow: 1;
}

.popupContainer {
  position: absolute;
  top: calc(var(--spacing-1) + 100%);
  left: 50%;
  right: 0;
  background-color: white;
  padding: var(--spacing-3);
  box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.1);
  border-radius: var(--rounded-md);
  transform: translateX(-50%);
  z-index: 100;
}

.dropdownContainer {
  padding: unset;
  max-height: var(--height-dropdown);
  overflow-y: auto;
}

.popupContainer.expandRight {
  top: 0;
  left: calc(var(--spacing-1) + 100%);
  transform: unset;
}

.popupContainer.expandLeft {
  top: 0;
  right: calc(var(--spacing-1) + 100%);
  left: unset;
  transform: unset;
}

.popupContainer.expandBottomRight {
  top: calc(var(--spacing-1) + 100%);
  left: 0;
  transform: unset;
}

.fitContent {
  width: fit-content;
}
