.pageHeader {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: var(--padding-page-header);
  box-sizing: border-box;
  gap: var(--gap-base);
}

.articleHeader {
  display: flex;
  width: 100%;
  padding-bottom: var(--padding-article-header);
  box-sizing: border-box;
}

.sectionHeader {
  display: flex;
  width: 100%;
  padding-bottom: var(--padding-section-header);
  box-sizing: border-box;
}

.dialogHeader,
.alertDialogHeader {
  display: flex;
  width: 100%;
  padding-bottom: var(--padding-dialog-header);
  box-sizing: border-box;
}

.titleContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
