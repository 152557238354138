.sideNav {
  width: 100%;
}

.logo {
  height: 40px;
  margin-bottom: var(--padding-section-bottom);
  box-sizing: border-box;
}

.logo.collapsed {
  margin-bottom: var(--padding-section-bottom);
  box-sizing: border-box;
}
