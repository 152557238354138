.modal,
.alertModal {
  position: fixed;
  transform: var(--modal-transform);
  background-color: white;
  padding: var(--modal-padding);
  padding-top: var(--modal-padding-t);
  top: var(--modal-top);
  bottom: var(--modal-bottom);
  left: var(--modal-left);
  right: var(--modal-right);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  overflow: var(--modal-overflow);
}

.alertModal {
  z-index: 1000;
}
