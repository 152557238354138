.icon path {
  fill: var(--color-button);
}
.icon path.hover {
  fill: var(--color-button-hover);
}

.icon path.red,
.icon path.warning {
  fill: var(--color-button-red);
}

.icon path.warning,
.icon path.warning.hover {
  fill: var(--color-button-red-hover);
}

.icon path.blue,
.icon path.emphasized {
  fill: var(--color-button-blue);
}
.icon path.blue.hover,
.icon path.emphasized.hover {
  fill: var(--color-button-blue-hover);
}

.icon path.green {
  fill: var(--color-button-green);
}
.icon path.green.hover {
  fill: var(--color-button-green-hover);
}

.icon path.white,
.icon path.important,
.icon path.alert {
  fill: var(--color-button-important);
}
.icon path.white.hover,
.icon path.white.hover .icon path.important.hover,
.icon path.alert.hover {
  fill: var(--color-button-important-hover);
}

.icon path.deemphasized {
  fill: var(--color-table-header);
}
.icon path.deemphasized.hover {
  fill: var(--color-table-header-hover);
}
