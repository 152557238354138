.app {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 100vh;
  padding: var(--padding-app-v) var(--padding-app-h);
  box-sizing: border-box;
}

.sideNavExpanded {
  width: var(--app-width-with-sidebar);
  margin-left: var(--sidebar-width);
}

.sideNavCollapsed {
  width: var(--app-width);
  margin-left: var(--sidebar-width-collapsed);
}

.modalOpen {
  height: 100vh;
  overflow: hidden;
}
